<template>
  <div>
    <div
      v-if="isLoading"
      class="grid-custom-input"
    >
      <b-form-checkbox
        v-for="ltt in ltts"
        :key="ltt.id"
        v-model="trangThaiIds"
        :value="ltt.id"
        class="mb-1"
        @change="checkItem"
      >
        {{ ltt.name }}
      </b-form-checkbox>
    </div>
    <div v-else>
      Đang tải dữ liệu
    </div>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormCheckbox,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    dataForm: {
      immediate: true,
      handler(newDate) {
        this.trangThaiIds = newDate.trangThaiIds
      },
    },
  },
  data() {
    return {
      isLoading: false,
      ltts: [],
      trangThaiIds: [],
    }
  },
  mounted() {
    this.getDataLoaiTrangThai()
  },
  methods: {
    getDataLoaiTrangThai() {
      this.isLoading = false
      try {
        this.$axios.get(END_POINTS.LOAI_TRANG_THAI.COMBOBOX).then(res => {
          if (res.data?.succeeded) {
            this.ltts = res.data.data
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    checkItem() {
      this.dataForm.trangThaiIds = this.trangThaiIds
    },
  },
}
</script>
