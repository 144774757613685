<template>
  <mau-phieu />
</template>
<script>
import MauPhieu from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/MauPhieu.vue'

export default {
  components: {
    MauPhieu,
  },
}
</script>
