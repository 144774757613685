<template>
  <div class="custom-grid-xl-container">
    <div class="grap-3">
      <div
        data-title="Thẻ tag"
        class="border-title h-100"
      >
        <div class="wrap-tag">
          <b-badge
            v-for="(tag, index) in tagsTemplate"
            :key="index"
            v-b-tooltip.click.top.v-secondary="'Sao chép!'"
            variant="primary"
            class="cursor-pointer"
            style="margin: 2px 1px;"
            @click="copyToClipboard(tag)"
          >
            {{ tag }}
          </b-badge>
        </div>
      </div>
    </div>
    <b-form class="grap-9">
      <b-form-group
        class="required"
        label="Mã mẫu phiếu"
        label-for="maMauPhieu"
      >
        <validation-provider
          v-slot="{ errors }"
          key="maMauPhieu"
          :rules="{ required: true }"
          name="maMauPhieu"
        >
          <b-form-input
            id="maMauPhieu"
            ref="maMauPhieu"
            v-model="dataForm.maMauPhieu"
            v-trim-input
            type="text"
            :autofocus="dataForm.id ? false : true"
            :class="{required: errors.length > 0}"
            placeholder="Nhập mã mẫu phiếu"
          />
          <span class="text-danger">{{ errors[0] }}</span>
          <span class="text-danger">{{ errorTrungMa }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="required"
        label="Tên mẫu phiếu"
        label-for="tenMauPhieu"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenMauPhieu"
          :rules="{ required: true }"
          name="tenMauPhieu"
        >
          <b-form-input
            id="tenQuocTich"
            ref="tenMauPhieu"
            v-model="dataForm.tenMauPhieu"
            v-trim-input
            type="text"
            :class="{required: errors.length > 0}"
            placeholder="Nhập tên mẫu phiếu"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Nhóm mẫu phiếu"
        label-for="nhomMauPhieu"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="nhomMauPhieu"
          :rules="{ required: true }"
          name="nhomMauPhieu"
        >
          <v-select
            id="nhomMauPhieu"
            ref="nhomMauPhieu"
            v-model="dataForm.nhomPhieuId"
            class="style-chooser"
            :options="npCbx"
            placeholder="Chọn nhóm mẫu phiếu"
            :class="{required: errors.length > 0}"
            :reduce="np => np.id"
            label="name"
            :filter="onFilterSelect"
          >
            <template #no-options>
              Không có dữ liệu
            </template>
          </v-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ghi chú"
        label-for="input-1"
      >
        <b-form-textarea
          id="textarea"
          v-model="dataForm.ghiChu"
          v-trim-input
          placeholder="Nhập ghi chú"
          rows="3"
          max-rows="6"
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          switch
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Nội dung mẫu phiếu"
        label-for="input-1"
      >
        <ckeditor
          v-model="dataForm.duongDan"
          :editor="editor"
          :config="editorConfig"
          class="ckeditor-class"
        />
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import { removeDiacritical } from '@/utils/index'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
// import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import '@/utils/vi'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tagsTemplate: [],
      editor: ClassicEditor,
      editorConfig: {
        language: 'vi',
        placeholder: 'Nhập nội dung...',
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          PageBreak,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'pageBreak',
            'code',
            'undo',
            'redo',
          ],
        },
      },
      npCbx: [],
      errorTrungMa: null,
    }
  },
  mounted() {
    this.getDataNhomPhieuCbx()
    this.getDataThuocTinhChiTiet()
  },
  methods: {
    getDataNhomPhieuCbx() {
      this.$axios.get(END_POINTS.NHOM_PHIEU.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.npCbx = res.data.data
        }
      })
    },
    getDataThuocTinhChiTiet() {
      this.$axios.get(END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.THUOC_TINH).then(res => {
        if (res.data?.succeeded) {
          const result = [
            'NgayHienTai',
            'NgayHienTai|DD',
            'NgayHienTai|MM',
            'NgayHienTai|YYYY',
            'NgayHienTai|hh:mm',
            'NgayBanGiao|DD',
            'NgayBanGiao|MM',
            'NgayBanGiao|YYYY',
            'NgayBanGiao|hh:mm',
            'NgayCoKetQua|DD',
            'NgayCoKetQua|MM',
            'NgayCoKetQua|YYYY',
            'NgayCoKetQua|hh:mm',
            'NgayHenTraKq|DD',
            'NgayHenTraKq|MM',
            'NgayHenTraKq|YYYY',
            'NgayHenTraKq|hh:mm',
            'NgayNop|DD',
            'NgayNop|MM',
            'NgayNop|YYYY',
            'NgayNop|hh:mm',
            ...res.data.data,
          ]
          this.tagsTemplate = result.sort()
        }
      })
    },
    errorMauPhieu(str) {
      this.errorTrungMa = str
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    copyToClipboard(value) {
      if (navigator.clipboard) {
        const copy = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toLowerCase())
        navigator.clipboard.writeText(`{{${copy}}}`)
      }
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, 800)
    },
  },
}
</script>
<style scoped lang="scss">
  * {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif
  }
  .border-title {
    border: 1.5px solid #EEEEEE;
    padding: 15px 10px;
    position: relative;
    &:before{
      content: attr(data-title);
      position: absolute;
      top: -10px;
      background: #fff;
      color: #333333;
      font-weight: 700;
      padding: 0 5px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
</style>
